import { v4 as uuidv4 }   from 'uuid'
import Showdown from 'showdown';


// Eylem Türü Tanımlama


// Eylem Oluşturucu Tanımlama
// Eylem oluşturucular
export const startStreamingMessage = (author) => ({
  type: 'START_STREAMING_MESSAGE',
  payload: { content: '', author }
});

export const updateStreamingMessage = (char) => ({
  type: 'UPDATE_STREAMING_MESSAGE',
  payload: char
});

export const endStreamingMessage = () => ({
  type: 'END_STREAMING_MESSAGE'
});



export const addMessage = (newMessage) => ({
  type:'ADD_MESSAGE',
  payload: {

    content:newMessage.content,
    author:newMessage.author,
    model:newMessage.model,
    imageUrl:newMessage.imageUrl
  }
});
export const handleChatHistory = (newMessage) => {

  const history = []
  const userMessages = newMessage.user_messages || [];
  const assistantMessages = newMessage.assistant_messages || [];
  const maxLength = Math.max(userMessages.length, assistantMessages.length);


  for (let i = 0; i < maxLength; i++) {
    if (i < userMessages.length) {
      history.push({
        content: userMessages[i],
        author: "user"
      });
    }
   
    if (i < assistantMessages.length) {
      if(newMessage.llm_info && newMessage.llm_info.model !== undefined && newMessage.llm_info.model==="dalle3"){
      
      
            const parsedData = JSON.parse(assistantMessages[i]);
            history.push({
              content: parsedData.text,
              imageUrl: parsedData.url,
              model: parsedData.model,
              author: "ai"
              
            });
          
      }else{
        if(assistantMessages[i].length>0){
          history.push({
            content: assistantMessages[i],
            author: "ai"
            
          });
        }
       
      }
     
    }
  }
 
  
  return {
  type:'HANDLE_HISTORY',
  payload: history
}};

export const handleBotChatHistory = (newMessage) => {

  const history = []
  const userMessages = newMessage.user_messages || [];
  const assistantMessages = newMessage.assistant_messages || [];
  const maxLength = Math.max(userMessages.length, assistantMessages.length);


  for (let i = 0; i < maxLength; i++) {
    if (i < userMessages.length) {
      history.push({
        content: userMessages[i],
        author: "user"
      });
    }
    if (i < assistantMessages.length) {
      
      history.push({
        content: assistantMessages[i],
        author: "ai"
      });
    }
  }

  
  return {
  type:'HANDLE_HISTORY',
  payload: history
}};



// Reducer
export const clearMessages = () => ({
  type: 'CLEAR_MESSAGES'
});




const initialState ={
  response:[{content:"Hello, how can I help you?",author:"ai"}],
   streamingMessage: null, 
  }

export const messagesReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_MESSAGE':
       
      return   {
        ...state,
        response: [...state.response, action.payload]
      };
    case 'CLEAR_MESSAGES':
      return initialState
    
      case 'START_STREAMING_MESSAGE':
        return {
          ...state,
          streamingMessage: { content: '', author: action.payload.author }
        };
  
      case 'UPDATE_STREAMING_MESSAGE':
        if (!state.streamingMessage) return state; // Eğer streaming başlamamışsa, bir şey yapma
        return {
          ...state,
          streamingMessage: {
            ...state.streamingMessage,
            content: state.streamingMessage.content + action.payload // Harf ekleniyor
          }
        };
  
      case 'END_STREAMING_MESSAGE':
        if (!state.streamingMessage) return state; // Eğer streaming başlamamışsa, bir şey yapma
        return {
          ...state,
          response: [...state.response, state.streamingMessage], // Mesajı nihai olarak ekle
          streamingMessage: null // Streaming bitti, durumu sıfırla
        };
    case 'HANDLE_HISTORY':
     
        return {
          ...state,
          response: action.payload
        };
    default:
      return state;
  }
};

