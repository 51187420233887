import React, { useState, useEffect, useRef } from 'react';
import useList from '@/components/isAuth/isAuth';
import loaderGif from "../../public/images/icons/loader-one.gif";
import Image from "next/image";

const Chatbot = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([
    { text: "Hi there. How can I help you today?", sender: "bot" }
  ]);
  const [input, setInput] = useState('');
  const list = useList();
  const sessionId = "";
  const messagesEndRef = useRef(null);
  const [isIndexing, setIsIndexing] = useState(false);


  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSend = async () => {
    setIsIndexing(true)

    if (input.trim()) {
      setMessages([...messages, { text: input, sender: "user" }]);
      setInput('');
      const response = await fetch('http://172.214.148.102:8005/generate_llm_response', {
        method: 'POST',
        headers: {
          'x-api-key': 'bXJ8HGJpbNDtnZM8UAtO8w8SH_qTDEDZolvzIb9ldcU',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({"user_id" : list[0], "user_question" : input , "session_id" : sessionId })
      });

      const data = await response.json();

      setIsIndexing(false)

      setMessages(prevMessages => [...prevMessages, { text: data.response, sender: "bot" }]);
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div style={styles.chatIcon} onClick={toggleChat}>
        💬
      </div>
      {isOpen && (
        <div style={styles.chatbotContainer}>
          <div style={styles.chatbotHeader}>Chatbot</div>
          <div style={styles.chatbotMessages}>
            <p style={{ textAlign: "center" }}>Created By <a href='https://www.evolvechat.com/' target='_blank'><b>EvolveChat</b></a></p>
            {messages.map((msg, index) => (
              <div key={index} style={msg.sender === 'bot' ? styles.botMessage : styles.userMessage}>
                {msg.text}
              </div>
            ))}
            <div ref={messagesEndRef} /> {/* Ref buraya eklendi */}
            {isIndexing && (
              <div className="author">
                <Image 
                src={loaderGif}
                width={40}
                height={40}
                alt="Loader Images"
                />
              </div>
            )}
          </div>
          <div style={styles.chatbotInput}>
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && handleSend()}
              placeholder="Enter a message..."
              style={styles.input}
            />
            <button onClick={handleSend} style={styles.button}>Send</button>
          </div>
        </div>
      )}
    </>
  );
};

const styles = {
  chatIcon: {
    position: 'fixed',
    bottom: '30px',
    right: '120px',
    width: '60px',
    height: '60px',
    borderRadius: '50%',
    backgroundColor: '#011827',
    border: '2px solid #7376aa',
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    fontSize: '24px',
    zIndex: 1000,
  },
  chatbotContainer: {
    position: 'fixed',
    bottom: '90px',
    right: '20px',
    width: '350px',
    height: '600px',
    border: '1px solid #ccc',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1000,
  },
  chatbotHeader: {
    backgroundColor: '#011827',
    color: 'white',
    padding: '10px',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  chatbotMessages: {
    flex: 1,
    padding: '10px',
    overflowY: 'auto',
    backgroundColor: '#f9f9f9',
    borderBottom: '1px solid #ccc',
  },
  botMessage: {
    marginBottom: '10px',
    padding: '10px',
    borderRadius: '10px',
    backgroundColor: '#e1f5fe',
    alignSelf: 'flex-start',
    maxWidth: '80%',
    wordWrap: 'break-word',
  },
  userMessage: {
    marginBottom: '10px',
    padding: '10px',
    borderRadius: '10px',
    backgroundColor: '#011827',
    color: 'white',
    alignSelf: 'flex-end',
    maxWidth: '80%',
    width: '100%',
    wordWrap: 'break-word',
    textAlign: 'left',
    marginLeft: 'auto',
  },
  chatbotInput: {
    display: 'flex',
    borderTop: '1px solid #ccc',
    padding: '10px',
    backgroundColor: '#fff',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
  },
  input: {
    flex: 1,
    border: 'none',
    padding: '10px',
    fontSize: '16px',
    borderRadius: '5px',
    marginRight: '10px',
    backgroundColor: '#f1f1f1'
  },
  button: {
    padding: '10px',
    backgroundColor: '#011827',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontWeight: 'bold'
  }
};

export default Chatbot;
