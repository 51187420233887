import { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { useAppContext } from "@/context/Context";

import logo from "../../public/images/logo/logo.png";
import Nav from "./Nav";

import { FormattedMessage,useIntl  } from 'react-intl';
import flag from "../../public/images/icons/en-us.png";
import flag2 from "../../public/images/icons/turkey.png";
import Select from "react-select";


const Header = ({ headerTransparent, headerSticky, btnClass,handleLanguageChange }) => {
  const { activeMobileMenu, setActiveMobileMenu } = useAppContext();
  const [isSticky, setIsSticky] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      if (currentScrollY > lastScrollY && currentScrollY > 200) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }

      setIsSticky(currentScrollY > 200);
      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  const headerStyle = {
    transition: "top 0.3s",
    top: isVisible ? "0" : "-100px", // Adjust -100px based on your header height
    position: isSticky ? "fixed" : "relative",
    width: "100%",
    zIndex: 1000, // Ensure the header stays on top
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: 120, // Select kutusunun genişliğini ayarla
      background:"transparent",
    }),
    menu: (provided) => ({
      ...provided,
      width: 120, // Menü genişliğini ayarla
    }),
    option: (provided) => ({
      ...provided,
      display: 'flex',
      justifyContent: 'center', // Seçenekleri ortala
      alignItems: 'center',
      whiteSpace: 'nowrap', // Taşma olmasın
    }),
    singleValue: (provided) => ({
      ...provided,
      display: 'flex',
      justifyContent: 'center', // Seçili değeri ortala
      alignItems: 'center',
    }),
  };

  const intl = useIntl();
  const language = intl.locale;
  const context = useAppContext() || {}; // useAppContext undefined dönerse boş obje kullanılır.
  const { shouldCollapseLeftbar = false } = context;

 
  const LanguageOptions = [
    { value: "en", label: "En", icon: flag },
    { value: "tr", label: "Tr", icon: flag2 },

    
  ];
  const temp = LanguageOptions.find(option => option.value === language);

  const [languageLocal, setLanguage] = useState(temp);


  const handleChangeLanguage = (selectedOption) => {
    setLanguage(selectedOption); // Seçilen dilin durumunu güncelle
    handleLanguageChange(selectedOption.value); // Seçilen dilin değerini ileti
  };

  const formatOptionLabel = ({ value, label, icon }) => (
    <div className="">
      <Image
        className="left-image"
        src={icon}
        alt={value}
        width={20}
        height={20}
      />
      <span style={{ marginLeft: "10px",color:"#000" }}>{label}</span>
    </div>
  );



  return (
    <>
      <header
      className={`rainbow-header header-default ${headerTransparent} ${headerSticky} ${
        isSticky ? "sticky" : ""
      }`}
    >
        <div className="container position-relative">
          <div className="row align-items-center row--0">
            <div className="col-lg-3 col-md-6 col-6">
              <div className="logo" style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                <Link href="/">
                  <Image
                    className="logo-light"
                    src={logo}
                    width={150}
                    height={35}
                    alt="LlmWizard Logo"
                  />
                </Link>
                <Select
                instanceId="sortByAuthor"
                className="bootstrap-select"
                classNamePrefix="bootstrap-select"
                defaultValue={languageLocal}
                onChange={handleChangeLanguage}
                options={LanguageOptions}
                formatOptionLabel={formatOptionLabel}
                closeMenuOnSelect={true}
                maxMenuHeight={150} // Menü yüksekliği sınırlaması
                styles={customStyles} // Stil özelleştirmesi
              />
              </div>
            </div>
            <div className="col-lg-9 col-md-6 col-6 position-static">
              <div className="header-right">
                <nav className="mainmenu-nav d-none d-lg-block">
                  <Nav />
                </nav>
                <div className="mobile-menu-bar ml--5 d-block d-lg-none">
                  <div className="hamberger">
                    <button
                      className="hamberger-button"
                      onClick={() => setActiveMobileMenu(!activeMobileMenu)}
                    >
                      <i className="feather-menu"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
