import Header from "@/components/Header/Header";
//import HeaderTop from "@/components/Header/HeaderTop/Header-Top";
import PopupMobileMenu from "@/components/Header/PopupMobileMenu";

import Features from "@/components/Home/Features";
import LlmSlider from "@/components/Home/LlmSlider.js";
import Welcome from "@/components/Home/Welcome.js";
import Context from "@/context/Context";
import Circle from "@/components/Home/Circle";

import Split from "@/components/Split/Split";
import ChatBot from "@/components/ChatBot/ChatBot";
import Design from "@/components/Design/Design";
/* import HeadDesign from "@/components/HeadDesign/HeadDesign"; */
import Cookie from "@/components/Cookies/Cookies";
import Pricing from "@/components/Pricing/Pricing";
import Accordion from "@/components/Accordion/Accordion";
//import Brands from "@/components/Brands/Brands";
//import CallToAction from "@/components/CallToAction/CallToAction";
import Footer from "@/components/Footer/Footer";
import EvolveLink from "@/components/EvolveLink/EvolveLink";

import PageHead from "../Head";

import { useIntl } from 'react-intl';

const HomePage = ({handleLanguageChange}) => {
  
  const intl = useIntl();
  return (
    <>
      <PageHead title={intl.formatMessage({ id: "home" })} />

      <main className="page-wrapper">
        <Context>
         {/* <HeaderTop />*/}
          <Header
            headerTransparent="header-transparent"
            headerSticky="header-sticky"
            btnClass="btn-small round"
            handleLanguageChange={handleLanguageChange}
          />
          <PopupMobileMenu />
          <Welcome/>
          <LlmSlider/>
          <Design/>
          <div className="separator-animated animated-true"></div>
          {/*<Home />
          <Service />
          <div className="separator-animated animated-true"></div>*/}
      
        {/*   <Timeline />
  <Separator top={false} /> */}
       
          {/* <HeadDesign/> */}

  
          
          <Features  />
          <ChatBot/>
          <div className="separator-animated animated-true"></div>
          {/* <NewFront /> */}
          <Pricing />
          <Circle />
          <div className="separator-animated animated-true"></div>
          {/*<Separator top={false} />
          <Brands />
          <Separator top={true} />
           <CallToAction />*/}
            <Accordion/>
            <EvolveLink/>
           <Footer />
        {/*  <Copyright /> */}
        </Context>
      </main>

      <Cookie />
    </>
  );
};

export default HomePage;
