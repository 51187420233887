import React, { useEffect, useRef,useState } from "react";
import { FormattedMessage ,useIntl  } from 'react-intl';

import llms from "../../public/images/menu-img/llms.png";
import Image from "next/image";

import m1 from '@/public/images/menu-img/en-llm.png'
import trm1 from '@/public/images/menu-img/tr-llm.png'
//import m2 from '@/public/images/menu-img/n2.png'
//import m3 from '@/public/images/menu-img/n3.png'
import m3 from '@/public/images/menu-img/templates5.png'
import trm3 from '@/public/images/menu-img/tr-templates.png'
import m2 from '@/public/images/menu-img/custom-bot.png'
import m4 from '@/public/images/menu-img/chatexample.png'
import trm4 from '@/public/images/menu-img/tr-chatexample.png'
import useList from '@/components/isAuth/isAuth';

const Features = () => {
    const list = useList();
    const intl = useIntl();

    const userID = list[0];
    const currentLocale = intl.locale;

  return (
    <>
    <style jsx>{`
        .custom-button {
    width: 320px;
    height: 56px;
    padding: 16px;
    border-radius: 28px;
    background: black;
    box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.2);
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: white;
    border: 0;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    transition: background 0.3s ease, box-shadow 0.3s ease;

  }
  .custom-button:hover {
    background: #3363E2;
    box-shadow: 0 6px 28px 0 rgba(0, 0, 0, 0.3);
  }
    `}</style>
      <div className="rainbow-accordion-area rainbow-section-gap">
        <div className="container" id="features-llmwizard">
                <h2><FormattedMessage id="features" /></h2>
                <div style={{gap:"96px",width:"100%",display:"flex",flexDirection:"column"}}>
                    <div style={{paddingBottom:"96px",gap:"0",borderBottom:"2px solid #100",display:"flex",flexDirection:"column"}}>
                        <h4> <svg style={{paddingRight:"5px"}} xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-stars" viewBox="0 0 16 16">
                        <path d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.73 1.73 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.73 1.73 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.73 1.73 0 0 0 3.407 2.31zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z"/>
                      </svg><FormattedMessage id="features.1.title" /></h4>
                        <div style={{alignItems:"normal",justifyContent:"space-between",display:"flex"}}>
                            <div style={{display:"flex",justifyContent:"space-between",flexDirection:"column"}}>
                                <ul style={{display:"flex",flexDirection:"column",gap:"12px",padding:"0",margin:"0",marginBlockStart:"60px",listStyle:"none"}}>
                                    <li style={{display:"inline-flex",gap:"8px",fontSize:"18px",fontWeight:"24px",lineHeight:"24px",textAlign:"left"}}> • <FormattedMessage id="features.1.list1" /></li>
                                    <li style={{display:"inline-flex",gap:"8px",fontSize:"18px",fontWeight:"24px",lineHeight:"24px",textAlign:"left"}}> • <FormattedMessage id="features.1.list2" /></li>
                                    <li style={{display:"inline-flex",gap:"8px",fontSize:"18px",fontWeight:"24px",lineHeight:"24px",textAlign:"left"}}> • <FormattedMessage id="features.1.list3" /></li>
                                    <li style={{display:"inline-flex",gap:"8px",fontSize:"18px",fontWeight:"24px",lineHeight:"24px",textAlign:"left"}}> • <FormattedMessage id="features.1.list4" /></li>
                                </ul>
                                <a  href={userID ? "https://llmwizard.com/chat" : "https://llmwizard.com/AuthPage"} className="custom-button"><FormattedMessage id="features.1.button" /></a>
                            </div>

                            {  currentLocale === "en"?   <Image className="features-img" height={560} width={560}  src={m1} alt="" />:<Image className="features-img" height={560} width={560}  src={trm1} alt="" /> }
                        </div>
                    </div>
                    <div style={{paddingBottom:"96px",gap:"0",borderBottom:"2px solid #100",display:"flex",flexDirection:"column"}}>
                        <h4><svg style={{paddingRight:"5px"}} xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-pencil" viewBox="0 0 16 16">
                        <path d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325"/>
                      </svg><FormattedMessage id="features.2.title" /></h4>
                        <div style={{alignItems:"normal",justifyContent:"space-between",display:"flex"}}>
                            <div style={{display:"flex",justifyContent:"space-between",flexDirection:"column"}}>
                                <ul style={{display:"flex",flexDirection:"column",gap:"12px",padding:"0",margin:"0",marginBlockStart:"60px",listStyleType:"disc"}}>
                                    <li style={{display:"inline-flex",gap:"8px",fontSize:"18px",fontWeight:"24px",lineHeight:"24px",textAlign:"left"}}> • <FormattedMessage id="features.2.list1" /></li>
                                    <li style={{display:"inline-flex",gap:"8px",fontSize:"18px",fontWeight:"24px",lineHeight:"24px",textAlign:"left"}}> • <FormattedMessage id="features.2.list2" /></li>
                                    <li style={{display:"inline-flex",gap:"8px",fontSize:"18px",fontWeight:"24px",lineHeight:"24px",textAlign:"left"}}> • <FormattedMessage id="features.2.list3" /></li>
         
                                </ul>
                                <a   href={userID ? "https://llmwizard.com/templates" : "https://llmwizard.com/AuthPage"} className="custom-button"><FormattedMessage id="features.2.button" /></a>
                            </div>

                            <Image className="features-img" height={560} width={560}  src={m2} alt="" />
                        </div>
                    </div>
                    <div style={{paddingBottom:"96px",gap:"0",borderBottom:"2px solid #100",display:"flex",flexDirection:"column"}}>
                        <h4><svg style={{paddingRight:"5px"}} xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-globe" viewBox="0 0 16 16">
                        <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m7.5-6.923c-.67.204-1.335.82-1.887 1.855A8 8 0 0 0 5.145 4H7.5zM4.09 4a9.3 9.3 0 0 1 .64-1.539 7 7 0 0 1 .597-.933A7.03 7.03 0 0 0 2.255 4zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a7 7 0 0 0-.656 2.5zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5zM8.5 5v2.5h2.99a12.5 12.5 0 0 0-.337-2.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5zM5.145 12q.208.58.468 1.068c.552 1.035 1.218 1.65 1.887 1.855V12zm.182 2.472a7 7 0 0 1-.597-.933A9.3 9.3 0 0 1 4.09 12H2.255a7 7 0 0 0 3.072 2.472M3.82 11a13.7 13.7 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5zm6.853 3.472A7 7 0 0 0 13.745 12H11.91a9.3 9.3 0 0 1-.64 1.539 7 7 0 0 1-.597.933M8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855q.26-.487.468-1.068zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.7 13.7 0 0 1-.312 2.5m2.802-3.5a7 7 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7 7 0 0 0-3.072-2.472c.218.284.418.598.597.933M10.855 4a8 8 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4z"/>
                      </svg> <FormattedMessage id="features.3.title" /></h4>
                        <div style={{alignItems:"normal",justifyContent:"space-between",display:"flex"}}>
                            <div style={{display:"flex",justifyContent:"space-between",flexDirection:"column"}}>
                                <ul style={{display:"flex",flexDirection:"column",gap:"12px",padding:"0",margin:"0",marginBlockStart:"60px",listStyle:"none"}}>
                                    <li style={{display:"inline-flex",gap:"8px",fontSize:"18px",fontWeight:"24px",lineHeight:"24px",textAlign:"left"}}> • <FormattedMessage id="features.3.list1" /></li>
                                    <li style={{display:"inline-flex",gap:"8px",fontSize:"18px",fontWeight:"24px",lineHeight:"24px",textAlign:"left"}}> • <FormattedMessage id="features.3.list2" /></li>
                                    <li style={{display:"inline-flex",gap:"8px",fontSize:"18px",fontWeight:"24px",lineHeight:"24px",textAlign:"left"}}> • <FormattedMessage id="features.3.list3" /></li>
                                    <li style={{display:"inline-flex",gap:"8px",fontSize:"18px",fontWeight:"24px",lineHeight:"24px",textAlign:"left"}}> • <FormattedMessage id="features.3.list4" /></li>
                                </ul>
                                <a  href={userID ? "https://llmwizard.com/chat" : "https://llmwizard.com/AuthPage"} className="custom-button"><FormattedMessage id="features.3.button" /></a>
                            </div>

                            {  currentLocale === "en"?  <Image className="features-img" height={560} width={560}  src={m3} alt="" />:<Image className="features-img" height={560} width={560}  src={trm3} alt="" /> }
                        </div>
                    </div>
                    <div style={{paddingBottom:"0px",gap:"0",display:"flex",flexDirection:"column"}}>
                        <h4><svg style={{paddingRight:"5px"}} xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-robot" viewBox="0 0 16 16">
                        <path d="M6 12.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5M3 8.062C3 6.76 4.235 5.765 5.53 5.886a26.6 26.6 0 0 0 4.94 0C11.765 5.765 13 6.76 13 8.062v1.157a.93.93 0 0 1-.765.935c-.845.147-2.34.346-4.235.346s-3.39-.2-4.235-.346A.93.93 0 0 1 3 9.219zm4.542-.827a.25.25 0 0 0-.217.068l-.92.9a25 25 0 0 1-1.871-.183.25.25 0 0 0-.068.495c.55.076 1.232.149 2.02.193a.25.25 0 0 0 .189-.071l.754-.736.847 1.71a.25.25 0 0 0 .404.062l.932-.97a25 25 0 0 0 1.922-.188.25.25 0 0 0-.068-.495c-.538.074-1.207.145-1.98.189a.25.25 0 0 0-.166.076l-.754.785-.842-1.7a.25.25 0 0 0-.182-.135"/>
                        <path d="M8.5 1.866a1 1 0 1 0-1 0V3h-2A4.5 4.5 0 0 0 1 7.5V8a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1v1a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-1a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1v-.5A4.5 4.5 0 0 0 10.5 3h-2zM14 7.5V13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7.5A3.5 3.5 0 0 1 5.5 4h5A3.5 3.5 0 0 1 14 7.5"/>
                      </svg><FormattedMessage id="features.4.title" /></h4>
                        <div style={{alignItems:"normal",justifyContent:"space-between",display:"flex"}}>
                            <div style={{display:"flex",justifyContent:"space-between",flexDirection:"column"}}>
                                <ul style={{display:"flex",flexDirection:"column",gap:"12px",padding:"0",margin:"0",marginBlockStart:"60px",listStyle:"none"}}>
                                    <li style={{display:"inline-flex",gap:"8px",fontSize:"18px",fontWeight:"24px",lineHeight:"24px",textAlign:"left"}}> • <FormattedMessage id="features.4.list1" /></li>
                                    <li style={{display:"inline-flex",gap:"8px",fontSize:"18px",fontWeight:"24px",lineHeight:"24px",textAlign:"left"}}> • <FormattedMessage id="features.4.list2" /></li>
                                    <li style={{display:"inline-flex",gap:"8px",fontSize:"18px",fontWeight:"24px",lineHeight:"24px",textAlign:"left"}}> • <FormattedMessage id="features.4.list3" /></li>
                                    <li style={{display:"inline-flex",gap:"8px",fontSize:"18px",fontWeight:"24px",lineHeight:"24px",textAlign:"left"}}> • <FormattedMessage id="features.4.list4" /></li>
                                </ul>
                                <a className="custom-button"  href={userID ? "https://llmwizard.com/chat" : "https://llmwizard.com/AuthPage"}><FormattedMessage id="features.4.button" /></a>
                            </div>
                             
                            { currentLocale === "en"? <Image className="features-img" height={560} width={560} src={m4} alt="" />:<Image className="features-img" height={560} width={560} src={trm4} alt="" />}
                        </div>
                    </div>
                    
                </div>
                
        </div>
      </div>
    </>
  );
};

export default Features;
